<template>
  <NuxtLayout name="site">
    <section class="fluid pt-0 md:pt-12 sm:px-4">
      <div class="fluid-inner sm:bg-main-25 sm:dark:bg-gray-800 rounded-3xl mt-16 lg:pb-8">
        <UContainer class="flex flex-col">
          <div 
            class="bg-main-25 dark:bg-gray-800 rounded-3xl w-full flex flex-col items-center px-4 pb-5  mb-4 "
          >
            <div class="text-center -mt-20">
              <UAvatar 
                size="4xl" 
                :src="avatarSrc" 
                class="ring-white ring-2 dark:ring-gray-500 relative scale-50 md:scale-100" 
              />
              <div v-if="$slots.title" class="-mt-10 md:mt-0">
                <slot name="title" />
              </div>
              <div v-else  class="-mt-10 md:mt-0">
                <h1 v-if="title" class="mt-8">{{ title }}</h1>
                <p v-if="subtitle" class="italic">{{ subtitle }}</p>
                  <div v-if="badge" class="flex gap-2 items-center justify-center mt-4">
                    <UBadge 
                      color="gray"
                      size="sm"
                      variant="subtle"
                      
                    >
                      <UIcon v-if="badgeIcon" :name="badgeIcon" class="w-3.5 h-3.5 mr-2" />
                      <span class="leading-6">{{ badge }}</span>
                    </UBadge>
                    <Hint v-if="badgeHint" :text="badgeHint"/>
                  </div>
                
                
              </div>
            </div>
            
            
          </div>

          <div 

            :class="!!tabs?.length ? 'sticky top-[3.75rem] md:top-[4.75rem] bg-white dark:bg-gray-900 sm:bg-main-25 sm:dark:bg-gray-800 -mx-2 px-2 pt-3 md:pt-2 z-20' : ''"
          >
            <div class="flex gap-2 md:gap-8 justify-between sm:pb-2 md:pb-1">
              <div v-if="!!tabs?.length" class="grow">
                <UTabs 
                  v-model="selected" 
                  :items="tabs" 
                  class="-mb-1 hidden md:block"
                  :ui="{
                    container: 'w-auto',
                    list: {
                      base: 'gap-2 md:gap-4 xl:gap-6',
                      background: 'bg-transparant dark:bg-transparant',
                      width: 'w-auto',
                      tab: {
                        base: 'font-bold',
                        active: 'text-primary-500',
                        inactive: 'text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300'
                      }
                    },
                    
                  }"
                />
                <div class="flex md:flex-row gap-3 items-stretch">
                  <USelectMenu
                    v-model="selected" :options="tabs"
                    class="md:hidden w-full"
                    option-attribute="label"
                    value-attribute="index"
                  >
                    <UButton color="white" trailing-icon="i-heroicons-chevron-down-20-solid" block class="justify-between" size="xl">
                      <span class="flex flex-row gap-4 items-center w-full text-sm">
                        <UIcon v-if="selectedItem" :name="selectedItem.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500" />
                        <span v-if="selectedItem" class="truncate">{{ selectedItem.label }}</span>
                      </span>
                    </UButton>
                    <template #option="{ option }">
                      <span class="flex flex-row gap-4 items-center w-full">
                        <UIcon :name="option.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500" />
                        <span class="truncate">{{ option.label }}</span>
                      </span>
                    </template>
                  </USelectMenu>
                </div>
                
              </div>
              
              <div class="flex gap-2">
                <slot name="filters" />
              </div>
            </div>
            
            <UDivider
              class="hidden sm:block" 
              :ui="{
                border: { base: 'border-white dark:border-gray-700'}
              }"
            />
          </div>
          
          <div class="grid grid-cols-1 md:grid-cols-12 gap-y-4 sm:gap-6 lg:gap-8 py-6">
              <slot :selected-tab="selected" />
          </div>
          
        </UContainer>
      </div>
    </section>
  </NuxtLayout>
</template>

<script lang="ts" setup>
type Tab = {
  index: number
  label: string
  icon: string
}

const props = defineProps<{
  avatarSrc: string
  title?: string,
  subtitle?: string
  badge?: string
  badgeIcon?: string
  badgeHint?: string
  tabs?: Tab[]
}>()



const route = useRoute()
const router = useRouter()

const selected = computed({
  get () {
    const index = props.tabs?.findIndex((item) => item.label === route.query.tab)
    if (index === -1) {
      return 0
    }

    return index
  },
  set (value: number) {
    if (!!props.tabs?.length && props.tabs[value]) {
      // Hash is specified here to prevent the page from scrolling to the top
      router.replace({ query: { 
        ...route.query,
        tab: props.tabs[value].label
       } })
    } else if (!!props.tabs && props.tabs[0]) {
      router.replace({ query: { ...route.query, tab: props.tabs[0].label } })
    }
  }
})

const selectedItem = computed(() => typeof selected.value === 'number' &&  props.tabs ? props.tabs[selected.value] as Tab : null)




// TODO implement slideover pane for filters
</script>
